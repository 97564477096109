import React from "react";
import './footer.css'


function Footer({ children }) {
    return (
        <div>
            <div className="phantom" />
            <div className="Footer">
                { children }
            </div>
        </div>
    )
}

export default Footer
