import React from "react";
import {useLocation} from 'react-router-dom';
// import {useParams} from 'react-router-dom';
import Grid from "./grid";

function Color () {
    const search = useLocation().search;
    let key = new URLSearchParams(search).get('key');
    key = stripTags(key);

    let secretKey = "gYh11d5dKh45s1sJd23kf";
    if (key === secretKey) {
        key = "307";
    } else if (!onlyColor(key)) {
        key = "000000";
    }

    // const { key } = useParams();
    return <Grid colorCode={key} />;
}

function stripTags (string) {
    let regex = /(<([^>]+)>)/gi;
    return string.replace(regex, "");
}

function onlyColor (string) {
    let regex = /^[A-Za-z0-9]{6}$/gi;
    return regex.test(string);
}

export default Color;
