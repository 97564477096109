import React from 'react'
import './grid.css';

function Grid({colorCode}) {
    if (colorCode === "307") {
        let style = {
            background: "url(https://colors.it-q.ru/secretPage.jpg) 100% 100% no-repeat",
            backgroundSize: "cover",
        };
        return (
            <div className="grid">
                <div className="title">Вы нашли секретную страницу</div>
                <div className="container">
                    <div></div>
                    <div style={style}></div>
                    <div></div>
                </div>
                <div className="title bold">Ваш код: {colorCode}</div>
                <div className="title">Ехал греча через речу...</div>
            </div>
        );
    }

    let style = {
        backgroundColor: '#' + colorCode,
    };
    let link = "https://colors.it-q.ru";
    return (
        <div className="grid">
            <div className="title">My color is:</div>
            <div className="container">
                <div></div>
                <div style={style}></div>
                <div></div>
            </div>
            <div className="title bold">#{colorCode}</div>
            <div className="title bold"><a className="button" href={link}>Get new color</a></div>
        </div>
    );
}

export default Grid
