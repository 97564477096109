import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Header, Footer, About, Color} from "./page";
import GetColor from "./core/api/GetColor";

let sign = <div>by <a href="https://t.me/pershinov">@pershinov</a></div>;

function App() {
    return (
        <div className="App">
            <Router>
                <Header/>
                <Routes>
                    <Route path='/' element={<GetColor/>}/>
                    <Route path='/about' element={<About/>}/>
                    <Route path='/color' element={<Color/>}/>
                    {/*<Route path='/color/:key' element={<Color/>}/>*/}
                </Routes>
                <Footer children={sign}/>
            </Router>
        </div>
    );
}

export default App;
