import React from "react";
import '../../page/grid.css';

// TODO create config with URL And API_KEY
const url = "https://api-color.it-q.ru/getColor";

const headers = {
    'Content-Type': 'application/json',
    'apiKey': 'testingApi',
}

class GetColor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            colorCode: ""
        };
    }

    componentDidMount() {
        fetch(url, {headers})
            // .then(async response => {
            //     const data = await response.json();
            //
            //     // check for error response
            //     if (!response.ok) {
            //         // get error message from body or default to response statusText
            //         const error = (data && data.status);
            //         return Promise.reject(error);
            //     }
            //
            //     this.setState({ colorCode: data.colorCode })
            //     console.log(data)
            // })
            // .catch(error => {
            //     this.setState({ errorMessage: error.toString() });
            //     console.error('There was an error!', error);
            // });
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        colorCode: result.colorCode
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
    //TODO get only color, then render with page component
    render() {
        const { error, isLoaded, colorCode } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <div>
                    <div className="title">Your color is:</div>
                    <div className="container">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="title">Loading...</div>
                </div>
            );
        } else {
            let style = {
                backgroundColor: '#' + colorCode,
            };
            let link = "/color?key=" + colorCode;
            let fullLink = "https://colors.it-q.ru" + link;
            return (
                <div className="grid">
                    <div className="title">Your color is:</div>
                    <div className="container">
                        <div></div>
                        <div style={style}></div>
                        <div></div>
                    </div>
                    <div className="title bold"><a className="colorLink" href={link}>#{colorCode} </a></div>
                    <div className="title bold"><a className="button" onClick={() => {this.copyAndAlert(fullLink)}}>Copy link to share</a></div>
                </div>
            );
        }
    }

    copyAndAlert (text) {
        navigator.clipboard.writeText(text);
    }

}

export default GetColor;
