import React from "react";
import "./about.css"

function About() {
    return (
        <div className="about">
            <div>
                <div>
                    <div>
                        <h1>About</h1>
                        <p>
                            This app has wrote "as is". Just because i can.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
