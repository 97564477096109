import React from 'react'
import './header.css';

function Header() {
    return (
        <div className="Header">
            <h1 className="Header-Subtitle">Get your own color</h1>
        </div>
    )
}

export default Header
